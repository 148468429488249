import { parseDate, ParsingOption as ChronoParsingOption } from "chrono-node";

/**
 * Returns a promise that resolves after ms milliseconds
 * @param ms the number of milliseconds to wait
 * @returns A valueless promise
 */
export const awaitTime = (ms: number): Promise<void> => new Promise((res) => setTimeout(res, ms));

export const parseTime = (value: string, options?: ChronoParsingOption): Date | undefined => {
  if (!value) return undefined;

  // help lazy input look like something chrono can parse (eg. "1" => "1:00")
  let newValue = /^\d{0,2}$/.test(value.trim()) ? `${value}:00` : value;
  // 1:00a => 1:00 a
  newValue = newValue.replace(/(?![\d]+)([ap]m?)/i, (m) => ` ${m.toLowerCase()}`);
  // 2030 => 8:30 pm (note: limit length to eliminate conflicts with long dates)
  if (newValue.length <= 4) {
    newValue = newValue.replace(/([\d]{1,2}(?!:)[\d]{2})/i, (m) => `${m.slice(0, -2)}:${m.slice(-2)}`);
  }

  return parseDate(newValue, undefined, options);
};
